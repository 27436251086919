<template>
  <div class="pages page24">
    <div class="page_content noTitle">
      <ul>
        <li>Circuler à droite sur la chaussée mais à un mètre du trottoir ou des véhicules en stationnement, faites attention aux ouvertures de portières.</li>
        <li>Ne pas rouler à plus de 20 km/h dans les zones d'intersection ou de passage piétons.</li>
        <li>Vous équiper pour être visible (gilet jaune ou autre vêtement voyant) et protégé (casque).</li>
        <li>Ne transporter un passager que sur un siège fixé au vélo, et pour un enfant de moins de 5 ans, sur un siège doté de repose-pieds et de courroie d'attache.</li>
      </ul>
      <p>Le vélo est un véhicule comme un autre. Le code de la route s'applique aux cyclistes comme à tous les usagers. En conséquence, toute infraction est passible d'une amende.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page24 {

    .page_content {
    margin-left: 6vw;
  }
  
}
</style>