<template>
  <div class="pages page29">
    <div class="page_img"><img src="../assets/p3-2a.jpg" alt="" /></div>
    <div class="page_content">
<h1>En voie de progression</h1>
<p>Au 1er janvier 2021, le Schéma national des véloroutes compte <strong>58</strong> itinéraires pour un linéaire total de <span><strong>25 587</strong></span> km d’après l’enquête d’actualisation des données de l’Observatoire national des véloroutes et voies vertes (ON3V ) menée fin <strong>2020</strong></p>
<p><strong>1783</strong> km se sont ouverts sur la seule année <strong>2020</strong>, notamment pour faciliter les déplacements des Français devant continuer leur activité professionnelle lors du premier confinement.</p>
<p>Le Schéma EuroVelo est, quant à lui, achevé à <strong>92,5</strong> %. Sur les <strong>8 806</strong> km des itinéraires européens qui concernent la France, <strong>8 149</strong> km sont ouverts au 1er janvier 2021.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page29 {
  .page_content {
    span {
      white-space: nowrap;
    }
  }
}
</style>