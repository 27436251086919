<template>
  <div class="pages page36">
    <div class="page_content noTitle">
      <h2>Les équipements obligatoires</h2>
      <ul>
        <li>Deux freins, avant et arrière.</li>
        <li>Un feu avant jaune ou blanc et un feu arrière rouge (la nuit ou le jour lorsque la visibilité est insuffisante).</li>
        <li>Un avertisseur sonore.</li>
        <li>Des catadioptres (dispositifs rétroréfléchissants) : de couleur rouge à l’arrière, de couleur blanche à l’avant, de couleur orange sur les côtés et sur les pédales.</li>
        <li>Le port d’un gilet rétroréfléchissant certifié est obligatoire pour tout cycliste (et son passager) circulant hors agglomération, la nuit, ou lorsque la visibilité est insuffisante.</li>
      </ul>
      <p>
        Le « plan vélo » prévoit l'autorisation pour les cyclistes de porter des
        dispositifs d'éclairage non-éblouissants, complémentaires à ceux
        jusqu'ici autorisés, à compter du 1er semestre 2019.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page36 {
  .page_content {
    margin-left: 6vw;
  }
}
</style>