<template>
  <div class="pages page27">
    <div class="page_img"><img src="../assets/piste_cyclable-camion.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <p>
        L'usage de ces voies, sauf mention contraire, n'est pas obligatoire pour
        les cyclistes. Une bonne chose au vu de l'état de certaines pistes
        cyclables ou du positionnement parfois dangereux des bandes cyclables
        (coexistence avec les piétons, présence de sortie de garage...).
      </p>
      <p>
        Depuis 2016, sur les voies limitées à <strong>30</strong> km/h, le cycliste est autorisé
        à circuler dans les deux sens – sauf disposition contraire - même si
        cela n'est pas matérialisé.
      </p>
      <p>
        Le double-sens cyclable sera étendu à l'ensemble de la voirie urbaine en
        agglomération où la vitesse maximale autorisée est de <strong>50</strong> km/h.
      </p>
    </div>
  </div>
</template>