<template>
  <div id="header">
    <div id="title">
      <img class="logo" src="../assets/Logo.png" alt="" />
      <img class="illustration" src="../assets/header.jpg" alt="" />
    </div>
    <div id="links">
      <ul>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 1,
          }"
          @click="goto(chapters[0].firstPage)"
        >
          États des lieux
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 2,
          }"
          @click="goto(chapters[1].firstPage)"
        >
          Conduites à risques
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 3,
          }"
          @click="goto(chapters[2].firstPage)"
        >
          Choisir la bonne voie
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 4,
          }"
          @click="goto(chapters[3].firstPage)"
        >
          Équipement
        </li>
        <li
          :class="{
            active: appState[$route.params.id_page - 1].activeChapter == 5,
          }"
          @click="goto(chapters[4].firstPage)"
        >
          Législation
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",

  methods: {
    goto: function (page) {
      var pageURL = "/page/" + page;
      this.$emit('close-side');
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss">
#header {
  background-color: #3f3853;
  width: 100%;
  height: 25vh;
  position: absolute;
  overflow: hidden;
}

#title {
  color: #5d9ad3;
  height: 18vh;
  width: 100%;

  img {
    &.logo {
      position: absolute;
      top: 0;
      left: 0;
      height: 17vh;
      z-index: 2;
    }

    &.illustration {
      position: absolute;
      top:0;
      right: 0;
      //height: 18vh;
      z-index: 1;
    }
  }

  h1 {
    font-size: 2.5vw;
    position: absolute;
    top: 2vh;
    left: 16%;
  }
}

#links {
  background-color: #7dc07d;
  //height: 8%;
  width: 100%;
  position: absolute;
  //top: 16%;
  bottom: 0;
  z-index: 3;
  border-top: #ffe137 solid 4px;
  border-bottom: #ffe137 solid 4px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    color: #3f56a6;
    display: inline-block;
    font-size: 2.5vw;
    font-weight: 700;
    margin: .6vh .6vw;
    padding: .8vh 0;
    //background-color: #5d9ad3;
    cursor: pointer;

    &:not(:last-child) {
      border-right: #ffe137 solid 4px;
      padding-right: 1.6vw;
    }

    &.active {
      color: white;
    }
  }
}
</style>