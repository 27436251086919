<template>
  <div class="pages page17">
    <div class="page_content">
      <div class="thumbsButton">
        <miniPlayer
          src="https://player.vimeo.com/external/557967047.hd.mp4?s=cd84daaf560efe9a9533285ce5fdf3bc1cb46cad&profile_id=175"
          thumbnail="thumbnail-legal.jpg"
        ></miniPlayer>
        <p>Introduction</p>
      </div>
      <div class="thumbsButton" @click="goto(18)">
        <img src="../assets/p2-1a_portiere.jpg" alt="" />
        <p>Ouverture de portière</p>
      </div>
      <div class="thumbsButton" @click="goto(19)">
        <img src="../assets/p2-1b_direction.jpg" alt="" />
        <p>Changement direction - Défaut signalement</p>
      </div>
      <div class="thumbsButton" @click="goto(20)">
        <img src="../assets/p2-1c_bus.jpg" alt="" />
        <p>Dépassement de véhicules lourds</p>
      </div>
      <div class="thumbsButton" @click="goto(21)">
        <img src="../assets/p2-1d_piste_cyclable.jpg" alt="" />
        <p>Passage d'une intersection</p>
      </div>
      <div class="thumbsButton" @click="goto(22)">
        <img src="../assets/p2-1e_remontee.jpg" alt="" />
        <p>Remonté de file</p>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page17",

  components: {
    miniPlayer,
  },

  methods: {
    goto: function (page) {
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss" scoped>
.page17 {
  top: -2.4vh;

  .page_content {
    margin: 3vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 70vh;
    padding-top: 4vh;

    .thumbsButton {
      //width:2vw;
      height: 25vh;
      flex: 0 1 25%;
      margin: 0 2vw;
      border: #f1e966 solid 4px;
      overflow: hidden;
      position: initial;
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 23.6vh;
      position: relative;
      z-index: 1;
      object-fit: cover;
    }

    video {
      width: 100%;
      position: initial;
    }

    p {
      background-color: #e0007a;
      color: #f1e966;
      position: relative;
      z-index: 2;
      top: -3.8vh;
      font-size: 1.2vw;
      text-align: center;
    }
  }
}
</style>