<template>
  <div class="pages page8">
    <div class="page_img"><img src="../assets/p1-3b.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <p>
        Pour la fédération française des usagers de la bicyclette, le
        développement des déplacements à vélo passe par la constitution d'un
        réseau dense de voies cyclables.
      </p>
      <p>
        Elles sont un gage de facilité de déplacement et de sécurité pour les
        usagers du vélo.
      </p>
      <p>
        <strong
          >Les risques à vélo constituent l'un des premiers freins à son
          développement.</strong
        >
      </p>
      <p>
        Le « plan vélo » prévoit la création d'un fonds national d'un montant de
        <strong>350</strong> millions € pour aider financièrement les communes
        et soutenir les projets de création d'axes cyclables ininterrompus.
      </p>
    </div>
  </div>
</template>