<template>
  <div>
    <router-view />
    <div id="screenPortrait"><img src="./assets/turncell.gif" alt="" /></div>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: "alwynnew";
  src: url("/font/alwynnew-bold.woff") format("woff");
}

@font-face {
  font-family: "MyriadPro";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Regular"), url("/font/Myriadpro-regular.woff") format("woff");
}

@font-face {
  font-family: "MyriadPro";
  font-style: normal;
  font-weight: bold;
  src: local("Myriad Pro Bold"), url("/font/Myriadpro-bold.woff") format("woff");
}

//Définition des couleurs de l'appli
$couleur1: #3F56A6;
$couleur2: #E42078;
$couleur3: #FFE137;
$couleur4: #7dc07d;

body {
  margin: 0;
}

#app {
  font-family: alwynnew;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#screenPortrait {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

@media (orientation: landscape) {
  #screenPortrait {
    display: none;
  }
}

@media (orientation: portrait) {
  #screenPortrait {
    display: flex;
  }
}
</style>
