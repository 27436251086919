<template>
  <div class="pages page7">
    <div class="page_img"><img src="../assets/bande-cyclable-sur-trottoir.jpg" alt="" /></div>
    <div class="page_content noTitle">
<h2>Des infrastrucutures dédiées</h2>
<p><strong>Les voies cyclables</strong> permettent d'isoler le cycliste de la circulation des véhicules motorisés ou de leur délimiter un espace dédié par une signalétique.</p>
<p>Bandes cyclables, pistes cyclables, ces voies s'adaptent au contexte urbain.</p>
<p>Leur qualité et leur entretien ne sont pas toujours d'un même niveau et ne sont pas toujours adaptés à une circulation domicile-travail.</p>
<p>De même, les discontinuités de ces pistes sont très fréquentes.</p>
    </div>
  </div>
</template>