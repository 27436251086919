<template>
  <div class="pages page12">
    <div class="page_img"><img src="../assets/cyclistes.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <h2>Les bienfaits de l'effet de masse</h2>
      <p>
        Plus les cyclistes sont nombreux dans l'espace public, plus le risque
        d'accident diminue en raison d'une meilleure appréhension de leur
        présence par les automobilistes. Le cycliste devient prévisible pour les
        autres usagers de la route.
      </p>
    </div>
  </div>
</template>