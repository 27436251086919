<template>
  <div class="pages page37">
    <div class="page_content noTitle">
      <h2>Quelques conseils pratiques pour votre équipement :</h2>
      <ul>
        <li>
          Porter un casque. Lors d’accident, il réduit la gravité des blessures
          à la tête.
        </li>
        <li>
          Porter de préférence des vêtements voyants, de couleur claire, ou un
          dispositif rétroréfléchissant par tous les temps.
        </li>
        <li>
          Veiller à l’état de vos pneus. Usés ou mal gonflés, ils tiennent moins
          bien la route et crèvent beaucoup plus facilement.
        </li>
        <li>
          Porter des gants ou des mitaines. Les mains font souvent l'objet de
          blessures, de plaies, en cas de chute.
        </li>
        <li>
          Équiper votre vélo d’un écarteur de danger qui incite les
          automobilistes à se tenir à l'écart.
        </li>
        <li>
          Éviter les accessoires vestimentaires trop longs ou déstabilisants
          (écharpe, sac en bandoulière), portez un sac adapté si besoin ou
          équipez-vous de sacoches.
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page37 {
  .page_content {
    margin-left: 6vw;
  }
}
</style>

