<template>
  <div class="pages page34">
    <div class="page_content">
      <h1>ÉQUIPEMENT</h1>
      <p>
        Un vélo en bon état, bien équipé, une tenue visible, un minimum de
        protection...
      </p>
      <p>
        L'équipement est essentiel pour votre sécurité comme pour celle des
        autres usagers de la route.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557966132.hd.mp4?s=49e05e9236d21df61c54ef5b672bde4f38ab33d5&profile_id=175"
        thumbnail="thumbnail-equipement.jpg"
      ></miniPlayer>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page34",

  components: {
    miniPlayer,
  },

};
</script>

<style lang="scss" scoped>
.page34 {
  .page_content {
    margin-left: 5vw;
    margin-right: 69vw;
  }

  video {
    right: 0;
    top: 0;
    height: 69vh;
    width: auto;
  }
}
</style>