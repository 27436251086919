<template>
  <div class="pages page3">
    <div class="page_img"><img src="../assets/vae.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Le vélo à assistance électrique</h1>
      <p>
        L'assistance du VAE se déclenche lors du pédalage et se coupe au-delà
        des <strong>25</strong> km/h.
      </p>
      <p>
        Alternative accessible au plus large public grâce à la réduction des
        efforts, le vélo à assistance électrique (VAE) s'est largement affirmé
        dans nos agglomérations.
      </p>
      <p>
        En 2016, <strong>107 322</strong> vélos à assistance électrique vendus
        en France.
      </p>
      <p>
        En 2017, ce chiffre a atteint <strong>254 870</strong> sur
        <strong>2,78</strong> millions de vélos vendus en France, soit près de
        <strong>10%</strong>.
      </p>
    </div>
  </div>
</template>