<template>
  <div class="pages page30">
    <div class="page_img"><img src="../assets/p3-2b.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <p>
        Les zones 30 se multiplient sur le réseau local de nombreuses communes
        et se généralisent dans les grandes villes.
      </p>
      <p>
        Quelques exemples : elles s'étendent sur près de <strong>90</strong> km à Lyon, elles
        occupent <strong>45%</strong> des voies parisiennes et doivent y être généralisées en
        dehors des grands axes en 2020.
      </p>
      <p>
        Sur Grenoble Alpes Métropole, <strong>42</strong> des <strong>49</strong> communes membres ont généralisé
        la zone 30.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page30 .page_img {
  img {
    object-fit:scale-down;
    object-position: 4vw -2vh;
  }
}
</style>