<template>
  <div id="sidemenu">
    <input id="panel-input" type="checkbox"/>
    <label id="panel-label" for="panel-input"></label>
    <div id="menulist">
      <ul>
        <li v-for="linkItem in chapters[(appState[$route.params.id_page - 1].activeChapter)-1].links" :key="linkItem.title" @click="goto(linkItem.page)">{{ linkItem.title }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Side",

  props: {
    sideState: Boolean,
  },

  beforeUpdate() {
    document.getElementById("panel-input").checked = this.sideState;
  },

  methods: {
    goto: function (page) {

      document.getElementById("panel-input").checked = false;
      //this.sideState = false;
      var pageURL="/page/"+page;
      this.$router.push({ path: pageURL });
    
    }
  }
};



</script>

<style lang="scss" scoped>
#menulist {
  background-color: #7dc07d;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1em;
  overflow: hidden;
  display: flex;
  align-items: center;

  ul {
    list-style-type: none;
    text-align: center;
    padding: 2vh 0;
    margin: 0;
  }

  li {
    color:white;
    font-size:2vw;
    margin:4vh 2vw;
    cursor:pointer;
  }
}

#sidemenu {
  position: fixed;
  z-index: 9999;
  width: 30vw;
  text-align: left;
  left: 0;
  top:28vh;
  transform: translateX(-100%);
  height: 60vh;
}

#panel-input {
  position: absolute;
  left: -999em;

  &:checked {
    ~ #panel-label {
      left: 196%;

      &::before {
        transform: translate(-50%, -50%) rotate(-135deg);
      }
    }

    ~ #menulist {
      transform: translateX(100%);
    }


  }
}

#panel-label {
  z-index: 1;
  background-color: #7dc07d;
  position: absolute;
  top: 43%;
  left: 96%;
  width: 4em;
  height: 4em;
  cursor: pointer;
  border-top-right-radius: 4vw;
  border-bottom-right-radius: 4vw;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 65%;
    box-sizing: border-box;
    width: 1em;
    height: 1em;
    border: .5em solid currentColor;
    border-width: .5em .5em 0 0;
    color:#fff;
    transition: all .5s;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

#panel-label,#menulist {
  transition: all .5s;
}
</style>