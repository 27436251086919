<template>
  <div class="pages page23">
    <div class="page_content">
      <h1>Conseils pour rouler en toute sécurité</h1>
      <p>Rappel des règles élémentaires de sécurité à vélo :</p>
      <ul>
        <li>Utiliser les voies cyclables lorsqu'elles existent.</li>
        <li>Garder une distance de sécurité d'au moins 1 mètre avec le véhicule devant vous, augmentez vos distances de sécurité en cas d'intempéries.</li>
        <li>Ne zigzaguer pas entre les véhicules.</li>
        <li>Aux intersections ne vous positionner pas le long d'un camion ou d'un bus, ou signalez vous, la principale menace en ville est l'angle mort des poids lourds.</li>
        <li>Signaler tout changement de direction aux intersections lorsque vous devez tourner à gauche et coupez une voie (en tendant le bras dans la direction où vous tournez).</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page23 {

    .page_content {
    margin-left: 6vw;
  }
  
}
</style>