<template>
  <div class="pages page13">
    <div class="page_img"><img src="../assets/accidentologie.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Accidentologie en quelques chiffres</h1>
      <p>
        Les cyclistes ont représenté environ <strong>5%</strong> des tués sur la route en 2017
        (<strong>172</strong> tués).<br/>Leur proportion a progressé de <strong>6%</strong> par rapport à 2016 et de
        <strong>17%</strong> depuis 2010.
      </p>
      <p>
        <strong>NB :</strong> Attention, tous les accidents provoquant l'hospitalisation d'un
        cycliste ne sont pas systématiquement renseignés par les forces de
        l'ordre qui alimentent la base de données accidents corporels de la
        circulation, notamment quand aucun usager d'un véhicule motorisé n'est
        impliqué.
      </p>
    </div>
  </div>
</template>