<template>
  <div class="pages page32">
     <div class="page_img"><img src="../assets/p3-3b2.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <h2>Piste cyclable</h2>
      <p>
        La piste cyclable est délimitée par une séparation physique infranchissable. Elle est privilégiée lorsque le flux de circulation est rapide (supérieur à 50 km/h) et élevé (supérieur à 8000 véhicules/jour) et/ou comprend une proportion importante de poids lourds. 
      </p>
      <h3>Mais...</h3>
      <p>
Elle est peu adaptée lorsque son tracé est discontinu. En cas de présence de nombreux carrefours, elle peut masquer la présence du cycliste aux intersections.
      </p>
    </div>
  </div>
</template>