<template>
  <div class="pages page11">
    <div class="page_img"><img src="../assets/velo_cadre.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <h2>Les indémnités kilométriques</h2>
      <p>
        Depuis le 13 février 2016, les entreprises du secteur privé peuvent
        prendre en charge tout ou partie des frais engagés par le salarié pour
        ses déplacements à vélo.
      </p>
      <p>
        Elle est fixé à <strogng>0,25 €</strogng> par kilomètre parcouru et est multipliée par la
        distance aller et retour la plus courte entre le domicile et le lieu de
        travail.
      </p>
      <p>
        Cette indemnité est exénorée de cotisations sociales dans la limite de
        <strogng>200</strogng>€.
      </p>
      <p>
        Le « plan vélo » prévoit le remplacement de cette indemnité kilométrique
        par un « forfait mobilité durable », contribution des employeurs privés
        et publics pouvant aller jusqu'à <strogng>400</strogng>€/an en franchise d'impôt et de
        cotisations sociales.
      </p>
      <p>
        L'Etat généralisera la mise en place de ce forfait pour tous ses agents
        d'ici 2020, à hauteur de <strogng>200</strogng>€/an.
      </p>
    </div>
  </div>
</template>