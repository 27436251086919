<template>
  <div id="home">
    <div class="title">
      <img src="../assets/Logo.png" alt="" />
    </div>

    <div id="homelink-container">
      <div class="homelink link1">
        <router-link to="/page/1">
          <img src="../assets/home-link1.jpg" alt="" />
          <div class="linkhover">
            <p>Accueil</p>
          </div>
        </router-link>
      </div>
      <div class="homelink link2">
        <router-link to="/page/38">
          <img src="../assets/home-link2.jpg" alt="" />
          <div class="linkhover">
            <p>Législation</p>
          </div>
        </router-link>
      </div>
    </div>

    <div id="video_link">
      <router-link to="/videoplayer"
        ><img src="../assets/home-link3.jpg" alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false,
      hover5: false,
    };
  },
};
</script>

<style lang="scss">
#home {
  background-color: #3f56a6;
  width: 100vw;
  height: 100vh;
}
.title {
  position: relative;
  top: 2vh;
  left: 2vw;
  width: 38vw;

  img {
    width: 100%;
  }
}

div#homelink-container {
  position: relative;
  width: 38vw;
  left: 2vw;
  //top: 1.4vh;
  //height: 60vh;
  margin-top: 7vh;

  .homelink {
    position: relative;
    //left: 3vw;
    height: 32vh;
    margin: 3vh 0;
    padding: 0;
    //width: 41vw;
    border-top: #ffe137 solid 4px;
    cursor: pointer;

    overflow: hidden;
    z-index: 1;

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .linkhover {
      background-color: #7dc07d;
      position: absolute;
      z-index: 2;
      width: 99%;
      height: 5vh;
      bottom: 0;
      border: #ffe137 solid 4px;

      p {
        z-index: 2;
        color: #3f56a6;
        font-family: "alwynnew";
        font-size: 2vw;
        margin: 0;
      }
    }
  }
}

#video_link {
  width: 56vw;
  height: 92.4vh;
  position: absolute;
  top: 2vh;
  right: 2vw;

  img {
    width: 100%;
    animation-duration: 2s;
    animation-name: open-eyes;
    animation-delay: 2s;
    position: relative;
      border-top: #ffe137 solid 4px;
  border-bottom: #ffe137 solid 4px;

    @keyframes open-eyes {
      from {
        top:50%;
        height: 0vh;
      }

      to {
        top:0;
        height: 100%;
      }
    }
  }
}
</style>