<template>
  <div class="pages page9">
    <div class="page_img"><img src="../assets/p1-3b.jpg" alt="" /></div>
    <div class="page_content noTitle">
<p>Parmi les autres aménagements destinés à faciliter les déplacements et la sécurité des cyclistes : </p>
<ul>
  <li>La généralisation des doubles sens cyclables dans les zones 30, voire, avec le « plan vélo » , dans les rues à <strong>50</strong> km/h (sauf danger).</li>
  <li>Des couloirs de bus ouverts aux vélos.</li>
  <li>Des « tourne à droite » cyclistes qui permettent de franchir les feux rouges de signalisation (lorsqu’un panonceau le prévoit expressément).</li>
  <li>La généralisation des sas vélos (annoncée dans le « plan vélo »).</li>
  <li>L’obligation de construire des garages à vélos sécurisés aux abords des gares(« plan vélo »).</li>
</ul>
    </div>
  </div>
</template>