<template>
  <div class="pages page1">
    <div class="page_img"><img src="../assets/cyclistes.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Un mode de déplacement en croissance</h1>
      <p>
        Les déplacements cyclistes représentent aujourd'hui environ
        <strong>3%</strong> en France. Aux Pays-Bas <strong>26%</strong>,
        Danemark <strong>19%</strong>, Allemagne <strong>10%</strong>.
      </p>
      <p>
        L'étalement urbain favorise toujours l'usage de la voiture mais une
        augmentation des déplacements à vélo a été constatée en agglomération.
      </p>
      <p>
        Part modale du vélo : près de <strong>6%</strong> à Bordeaux ou Colmar
        et <strong>7,5%</strong> à Strasbourg, Nantes et La Rochelle.
      </p>
      <p>
        Le rapport des Départements et Régions Cyclables recense une hausse de
        <strong>8%</strong> de la fréquentation cyclable en 2017 par rapport à
        2016, dont :
      </p>
      <ul>
        <li><strong>8%</strong> pour le vélo utilitaire,</li>
        <li><strong>6%</strong> pour le vélo loisirs.</li>
      </ul>
      <miniPlayer
        src="https://player.vimeo.com/external/557969483.hd.mp4?s=10a2f6b9daa1b05b911c811b18e33452b2373d72&profile_id=175"
        thumbnail="thumbnail-intro.jpg"
      ></miniPlayer>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page1",

  components: {
    miniPlayer,
  },
};
</script>

<style lang="scss" scoped>
.page1 {
  .page_img {
    img {
      object-fit: scale-down;
      //object-position: 8vw 0vh;
    }
  }

  video {
    bottom:5vh;
  }
}
</style>