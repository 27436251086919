<template>
  <div class="pages page10">
    <div class="page_img"><img src="../assets/velos_lille.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <h2>La mise en place de dispositifs de vélo en libre service</h2>
      <p>
        Une quarantaine de collectivités sont aujourd'hui équipées de vélos en
        libre service. Des dispositifs de prêts de vélos longue durée, notamment
        de vélo à assistance électrique, se sont multipliés.
      </p>
      <h2>Les aides publiques</h2>
      <p>La pratique du vélo a notamment bénéficié :</p>
      <ul>
        <li>Des aides à l'achat de vélo (aides des collectivités et de l'Etat).</li>
        <li>Des aides à l'achat de VAE.</li>
        <li>Du remboursement des frais kilométriques par les employeurs privés.</li>
        <li>Des aides aux plans de déplacement des entreprises et aux associations pour la promotion du vélo.</li>
      </ul>
    </div>
  </div>
</template>