<template>
  <div class="pages page21">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/p2-1d_piste_cyclable.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Passage d'une intersection</h1>
      <p>
        Le cycliste circule sur une bande cyclable ( partie de la chaussée
        réservée aux vélos ). Au passage d'une intersection, malgré sa présence
        sur une chaussée partagée avec les autres véhicules motorisés, une
        automobile lui coupe la route en tournant à droite.
      </p>
      <p>
        Ce risque est accru lorsque le cycliste circule sur une piste cyclable (
        voie séparée de la chaussée ) s'interrompant à une intersection ou sur
        le trottoir (ce qui est interdit). En effet, ne partageant pas la même
        chaussée, le véhicule automobile comme le cycliste ont une moindre
        visibilité.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557968747.hd.mp4?s=255b87459936fe5870166997cfd8a0db8e2c837d&profile_id=175"
        thumbnail="thumbnail-risque4.jpg"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <ul>
          <li>Sur une intersection, ralentir et s'assurer de l'absence de tout véhicule changeant de direction.</li>
          <li>Ne pas rouler sur les trottoirs ( c'est interdit ) pour rester visible des automobilistes aux intersections.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page21",

  data() {
    return {
      showConseil: false,
    };
  },

  components: {
    miniPlayer,
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page21 {

  .page_content {
    margin-left: 40vw;
  }

  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>