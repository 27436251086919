<template>
  <div class="pages page33">
    <div class="page_img"><img src="../assets/p3-3c.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <h2>Zone 30</h2>
      <p>
        Comme son nom l'indique, la vitesse y est limitée à <strong>30</strong> km/h. Les
        aménagements pour cyclistes n'y sont pas obligatoires. Toutes les
        chaussées sont à double sens pour les cyclistes sauf mention contraire.
        Une vitesse réduite des véhicules motorisés, plus proche de celle des
        cycles réduit le nombre et la gravité des acccidents. Davantage
        d'anticipation possible et de visibilité des usagers de la voirie.
      </p>
      <h3>Mais...</h3>
      <p>
        Les zones 30 ne sont implantées qu'en agglomération dense, sur le réseau
        local.
      </p>
    </div>
  </div>
</template>