<template>
  <div class="page">
    <Header @close-side="closeSide()"></Header>
    <div id="content">
      <Side :side-State="sideState"></Side>
      <Content></Content>
    </div>
    <Nav @credit-update="creditUpdate"></Nav>
    <Credit v-show="credits" @click="creditUpdate(false)"></Credit>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Nav from "@/components/Nav.vue";
import Side from "@/components/Side.vue";
import Content from "@/components/Content.vue";
import Credit from "@/components/Credit.vue";

export default {
  name: "Page",

  data() {
    return {
      credits : false,
      sideState : false,
    };
  },

  components: {
    Header,
    Nav,
    Side,
    Content,
    Credit,
  },

  methods: {
    closeSide(){
      this.sideState = false;
      console.log('close side');
    },

    creditUpdate(e) {
      this.credits = e;
    }
  }
};
</script>

<style lang="scss">
.page {
  overflow: hidden;
}

#content {
  position: absolute;
  top: 21vh;
  overflow: hidden;
}
</style>