<template>
  <div class="pages page31">
    <div class="page_img"><img src="../assets/p3-3a2.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Bandes ou piste cyclables - avantages et inconvénients</h1>
      <h2>Bande cyclable</h2>
      <p>
        La bande cyclable place le cycliste sur la chaussée. Elle est
        privilégiée en agglomération, fonctionne bien sur les zones de
        carrefours et assure une bonne visibilité entre automobilistes et
        cyclistes
      </p>
      <h3>Mais...</h3>
      <p>
        Lorsque la vitesse des véhicules motorisés et la densité du trafic sont
        importantes, elle protège mal le cycliste. En outre, les véhicules
        automobiles peuvent plus facilement circuler ou stationner sur cet espace qui ne leur est pas dédié.
      </p>
    </div>
  </div>
</template>