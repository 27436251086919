<template>
  <div class="pages page2">
    <div class="page_img"><img src="../assets/cargobike.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <p>
En France, <strong>60 %</strong> des trajets effectués font moins de <strong>5</strong> kilomètres. 
En agglomération, le vélo est en moyenne plus rapide que l'automobile - et plus économique - sur des trajets allant jusqu'à <strong>6</strong> kilomètres.
      </p>
      <p>
Dans le cadre de l'objectif de réduction des gaz à effet de serre et des nuisances, il mobilise les efforts des collectivités et de l'Etat.
      </p>
      <p>
L'usage du vélo s'est également développé dans un cadre professionnel, principalement chez les sociétés de livraison.
      </p>
      <p>
Enquête MTI Conseil et TNS Sofres (novembre et décembre 2012)
      </p>
    </div>
  </div>
</template>