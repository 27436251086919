<template>
  <div class="pages page16">
    <div class="page_content noTitle">
<h2>Situations et causes des accidents</h2>
<p>Dans <strong>70%</strong> des cas, le cycliste chute seul :</p>
<ul>
  <li>En tentant d'éviter un piéton, un obstacle (trottoir, véhicule en stationnement...), ou l'ouverture d'une portière.</li>
  <li>Suite à une glissade sur sol mouillé.</li>
  <li>De nuit, par mauvais temps.</li>
  <li>Par déséquilibre lié au port d'un sac ou d'un chargement.</li>
</ul>
<p>Dans <strong>30%</strong> des cas, les accidents impliquent un autre usager, dont <strong>46%</strong> dans un conflit de trajectoire.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page16 {

  .page_content {
    margin-left:6vw;
  }
}
</style>