<template>
  <div class="pages page20">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/p2-1c_bus.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Dépassement d'un poids lourd ou d'un bus à l'arrêt</h1>
      <p>Lors de l'arrêt d'un bus ou dans le cas d'un poids lourd en stationnement, le cycliste s'engage sur la droite ou sur la gauche du véhicule.</p>
      <p>
Un véhicule automobile arrive par l'arrière, le cycliste coupe sa trajectoire.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557965420.hd.mp4?s=11890fd3ae5e526022486b29245d7b46418d2b35&profile_id=175"
        thumbnail="thumbnail-risque3.jpg"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <ul>
          <li>Eviter de se positionner le long d'un camion ou d'un bus, faire attention à l'angle-mort du véhicule.</li>
          <li>Être vigilant au changement de direction du bus à une intersection, tournant à droite ou à gauche.</li>
          <li>Assurer sa visibilité auprès du conducteur.</li>
          <li>Attention aux piétons pouvant traverser la chaussée devant un poids lourd.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page20",

  data() {
    return {
      showConseil: false,
    };
  },

  components: {
    miniPlayer,
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page20 {

  .page_content {
    margin-left: 40vw;
  }

  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>