<template>
  <div class="pages page38">
    <div class="page_content">
      <div class="signs">
        <div class="sign">
          <img
            src="../assets/Panneaux-B9b-680.png"
            alt=""
            @click="slideSelect = 1"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-B22a-680.png"
            alt=""
            @click="slideSelect = 2"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-B27a-M4d1-680.png"
            alt=""
            @click="slideSelect = 3"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-B40-680.png"
            alt=""
            @click="slideSelect = 4"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-B52-680.png"
            alt=""
            @click="slideSelect = 5"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-B54-680.png"
            alt=""
            @click="slideSelect = 6"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-C24a-680.png"
            alt=""
            @click="slideSelect = 7"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-C24c-680.png"
            alt=""
            @click="slideSelect = 8"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-C113-680.png"
            alt=""
            @click="slideSelect = 9"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-C115-680.png"
            alt=""
            @click="slideSelect = 10"
          />
        </div>
        <div class="sign">
          <img
            src="../assets/Panneaux-M12a_b-680.png"
            alt=""
            @click="slideSelect = 11"
          />
        </div>
      </div>
    </div>

    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 1"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-B9b-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Accès interdit aux cyclistes</h2>
        <p>
          Le panneau B9 signifie l'interdiction aux cyclistes d'emprunter une
          voie. On le trouvera, par exemple, sur une voie d'accès à une
          autoroute ou au périphérique parisien.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 2"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-B22a-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Piste ou bande cyclable obligatoire</h2>
        <p>
          Le panneau B22a signifie l’obligation pour les cyclistes de circuler
          sur la piste cyclable ou la bande à l’entrée de laquelle ce dernier
          est placé. Il signifie également l’interdiction implicite pour les
          autres véhicules et les piétons d’utiliser cette voie.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 3"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-B27a-M4d1-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>
          Voie réservée aux véhicules de transport en commun et aux cycles
        </h2>
        <p>
          Ce panneau indique que la voie est réservée aux transports en commun
          (les voies de bus) et aux vélos. Bien entendu, en l’absence du vélo
          sous le panneau bleu circulaire, il est strictement interdit aux vélos
          de prendre les voies de bus.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 4"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-B40-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Fin d'une piste ou bande cyclable</h2>
        <p>
          Le panneau B40 signifie la fin d'une piste ou d'une bande cyclable
          conseillée et réservée aux cycles à deux ou trois roues.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 5"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-B52-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Zone de rencontre</h2>
        <p>
          Le panneau B52 indique une zone de rencontre entre véhicules à moteurs
          (voitures, cyclomoteurs), piétons et cyclistes. Dans cette zone la
          priorité est donnée aux piétons et la vitesse maximale est de 20km/h.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 6"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-B54-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Aire piétonne</h2>
        <p>
          Panneau indiquant que les cyclistes ne doivent pas oublier que les
          piétons sont prioritaires. Ils doivent donc circuler au pas et ne pas
          gêner les piétons..
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 7"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-C24a-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Double-sens cyclable</h2>
        <p>
          Ce panneau autrement appelé « sens interdit sauf vélos » indique qu’il
          y a un sens unique classique pour les autres véhicules alors qu’il est
          bi-directionnel pour les vélos. Dans les zones de rencontre et les
          zones 30, toutes les rues à sens unique sont à double sens pour les
          cyclistes à moins d’une indication contraire.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 8"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-C24c-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Passage de cyclistes à contre-sens</h2>
        <p>
          Ce panneau C24c s'adresse à tous les usagers, cyclistes et
          automobilistes compris; au prochain carrefour, la rue est à sens
          unique mais des cyclistes ont le droit de venir à contre-sens.
        </p>
        <p>
          Les panneaux C24c y indiquent les conditions particulières de
          circulation telles que nombre de voies, sens de circulation par voie,
          ou indications concernant une ou plusieurs voies de la chaussée
          embranchée.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 9"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-C113-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Piste ou bande cyclable conseillée</h2>
        <p>
          Le panneau C113 indique aux cyclistes qu’il est conseillé d’utiliser
          la piste ou la bande cyclable introduite par cette signalisation. Il
          indique comme le panneau B22a que cette voie leur est réservée. Il est
          donc interdit aux piétons et aux autres véhicules de l’utiliser. Cette
          voie n'est pas pour autant obligatoire pour les cyclistes.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 10"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-C115-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Voie verte</h2>
        <p>
          Ce panneau signale une route exclusivement réservée à la circulation
          des véhicules non motorisés, des piétons et des cavalier.
        </p>
      </div>
    </div>
    <div
      class="sign_description"
      @click="slideSelect = 0"
      v-show="slideSelect == 11"
    >
      <div class="signDescriptionPicture">
        <img src="../assets/Panneaux-M12a_b-680.png" alt="" />
      </div>
      <div class="signDescriptionText">
        <h2>Tourne-à-droite</h2>
        <p>
          Depuis 2010, ce panneau donne l’autorisation aux vélos à un feu de
          tourner à droite ou d’aller tout droit alors que le feu est rouge. Il
          ne dispense pas néanmoins du respect de la priorité, envers les
          piétons notamment. C’est le seul cas où il est possible de passer un
          feu rouge à vélo. Sans signalisation, il constitue une infraction qui
          entraîne une amende forfaitaire de 135€.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page38",

  data() {
    return {
      slideSelect: 0,
    };
  },
};
</script>


<style lang="scss" scoped>
.page38 {
  .page_content {
    margin: 0;
  }

  .signs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100vw;
    height: 71vh;

    .sign {
      flex: 0 1 16%;
      text-align: center;
      margin-top: 8vh;

      img {
        width: 12vw;
        height: 13vw;
        cursor: pointer;
      }
    }
  }

  //.signs_content {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //   width: 100%;
  //   height: 100%;

  .sign_description {
    background-color: #fff;
    border: #3f56a6ee 4px solid;
    border-radius: 2vw;
    box-shadow: 5px 5px 10px #808080a0;
    width: 70vw;
    height: 60vh;
    //margin-top: 8vh;
    //margin-left: 16vw;
    position: absolute;
    top: 7vh;
    left: 16vw;

    .signDescriptionPicture {
      float: left;
      margin-top: 1vh;
      margin-right: 1vw;
    }

    img {
      width: 30vw;
      //height: 31vw;
    }

    .signDescriptionText {
      text-align: left;
      padding-right: 3vw;
      height: 98%;
      padding-top: 12%;

      h2 {
        font-size: 1.6vw;
        color: #3f56a6;
      }

      p {
        font-size: 1.4vw;
        font-family: MyriadPro;
        color: #8d8e85;
      }
    }
  }
  //}
}
</style>