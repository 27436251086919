<template>
  <div class="pages page22">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/p2-1e_remontee.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Remontée de file</h1>
      <p>
En situation de circulation dense, d'embouteillages sur une double voie, le cycliste évolue entre les véhicules.
      </p>

      <miniPlayer
        src="https://player.vimeo.com/external/557968143.hd.mp4?s=8e5ed6abfddb3125bb2480f2e4500493a3119900&profile_id=175"
        thumbnail="thumbnail-risque5.jpg"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <ul>
          <li>Limiter sa vitesse.</li>
          <li>Être vigilant au clignotant des véhicules effectuant un changement de voie.</li>
          <li>Ne pas zigzaguer entre les véhicules.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page22",

  data() {
    return {
      showConseil: false,
    };
  },

  components: {
    miniPlayer,
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page22 {

  .page_content {
    margin-left: 40vw;
  }

  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>