<template>
  <div id="nav">
    <img
      id="nav-precedent"
      src="../assets/nav-precedent.png"
      alt=""
      @click="goto(appState[$route.params.id_page - 1].previousPage)"
    />
    <img
      id="nav-suivant"
      :class="
        appState[$route.params.id_page - 1].nextPage == 'end'
          ? 'disableNav'
          : ''
      "
      src="../assets/nav-suivant.png"
      alt=""
      @click="goto(appState[$route.params.id_page - 1].nextPage)"
    />
    <img
      id="nav-home"
      src="../assets/nav-home.png"
      alt=""
      @click="goto('home')"
    />
    <img
      id="nav-credit"
      src="../assets/nav-credit.png"
      alt=""
      @click="goto('credit')"
    />
  </div>
</template>

<script>
export default {
  name: "Nav",

  methods: {
    goto: function (page) {
      var pageURL = "";

      if (page == "home") {
        this.$router.push({ path: "/" });
      } else if (page == "credit") {
        console.log("credit");
        this.credits = true;
        this.$emit('credit-update', true);
      } else if (page == "end") {
        console.log(page);
      } else {
        pageURL = "/page/" + page;
        this.$router.push({ path: pageURL });
      }
    },
  },
};
</script>

<style lang="scss">
#nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 8vh;
  background-color: #3F56A6;

  img {
    cursor: pointer;
    width:3.6vw;
    margin-top:.8vh;
  }

  #nav-suivant {
    position: absolute;
    left: 54vw;
  }

  #nav-home {
    position: absolute;
    right: 8vw;
  }

  #nav-credit {
    position: absolute;
    right: 2vw;
  }

  .disableNav {
    opacity: .40;
    cursor: initial;
  }
}
</style>