<template>
  <div class="pages page5">
    <div class="page_img"><img src="../assets/p1-2c.jpg" alt="" /></div>
    <div class="page_content noTitle">
<h2>Les limites du VAE</h2>
<p>Attention, les vélos électriques bénéficiant d'une assistance au-delà d'une vitesse de <strong>25</strong> km/h changent de catégorie. </p>
<p>Reconnu alors comme cyclomoteur (voire comme une moto si sa vitesse dépasse les <strong>45</strong> km/h), les possesseurs de ces véhicules doivent alors :</p>
<ul>
  <li>Disposer d'une plaque d'immatriculation et d'une carte grise.</li>
  <li>Souscrire une assurance de type cyclomoteur.</li>
  <li>Porter un casque motocycle.</li>
</ul>
<p>Ce type de véhicule n'est pas autorisé à rouler sur les voies cyclables.</p>
    </div>
  </div>
</template>