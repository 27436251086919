<template>
  <div class="pages page35">
    <div class="page_content">
      <div class="equipmentPictures" @click="slideSelect = 0">
        <img
          src="../assets/equipements-2roues-fond.png"
          alt=""
          :class="slideSelect != 0 ? 'soften' : ''"
        />
        <img
          src="../assets/equipements-2roues-recommandes.png"
          alt=""
          v-show="slideSelect == 2"
        />
        <img
          src="../assets/equipements-2roues-obligatoires.png"
          alt=""
          v-show="slideSelect == 1"
        />
      </div>
      <div class="equipmentButtons">
        <button @click="slideSelect = 1" :class="slideSelect == 2 ? 'unselect' : ''">Équipements obligatoires</button>
        <button @click="slideSelect = 2" :class="slideSelect == 1 ? 'unselect' : ''">Équipements recommandés</button>
      </div>
      <div class="equipmentWarning" v-show="slideSelect == 1">
        <h2>ATTENTION :</h2>
        <p>le siège enfant adapté est obligatoire pour les enfants de moins de 5 ans</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page35",

  data() {
    return {
      slideSelect: 0,
    };
  },
};
</script>



<style lang="scss" scoped>
.page35 {
  height: 71vh;
  margin-top: 2vh;

  .page_content {
    width: 100vw;
    height: 71vh;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;

    .equipmentPictures {
      //position: absolute;
      //top: 0;
      width: 100%;
      height: 71vh;
      padding-left: 6vw;

      img {
        height: 69vh;
        position: absolute;
        top: 0;

        &.soften {
          opacity: 0.12;
        }
      }
    }

    .equipmentButtons {
      position: absolute;
      top: 2vh;
      left: 2vw;
      width: 8vw;
      height: 5vh;
      margin-top: 2vh;

      button {
        position: initial;
        background: initial;
        color: #3f56a6;
        font-size: 2vw;
        font-family: alwynnew;
        width: 26vw;
        height: 6vh;
        text-align: left;

        &.unselect {
          opacity: .5;
        }
      }
    }

    .equipmentWarning {
      background-color: #7dc07d80;
      position:absolute;
      top: 4vh;
      right: 2vw;
      width:20vw;
      padding-left: 1vw;
    }
  }
}
</style>