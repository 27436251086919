<template>
  <div>
    <button class="close" @click="goto(1)">X</button>
    <Video
      url="https://player.vimeo.com/external/557968469.hd.mp4?s=c27dd1a6c0bfc4ff976a53cc8d2df0dadaaa9570&profile_id=175"
    />
  </div>
</template>

<script>
import Video from "@/components/Video.vue";

export default {
  name: "Videoplayer",
  components: {
    Video,
  },

  methods: {
    goto: function (page) {
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss">
.close {
  position: absolute;
  z-index: 9999;
  top: 2vh;
  right: 2vw;
  cursor:pointer;
}
</style>
