<template>
  <div class="pages page15">
    <div class="page_img"><img src="../assets/p1-4c_g1.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <h2>Attention fragile !</h2>
      <p>
        Risque pour un cycliste d'être tué sur la route : <strong>3</strong> fois plus élevé que
        pour un automobiliste et <strong>16</strong> fois plus élevé d'être gravement blessé. <strong>44%</strong>
        des accidents ayant eu pour conséquence une personne tuée ou gravement
        blessée résultent d'une collision sur le côté.
      </p>
      <h2>Facteur saisonnier</h2>
      <p>
        Une augmentation progressive des cyclistes blessés ou tués est constatée
        entre mars et septembre pour diminuer ensuite.
      </p>
      <p>Elle impacte davantage le vélo loisirs que le vélo utilitaire.</p>
      <p>
        La visibilité en fonction de l'environnement est importante : <strong>16%</strong> des
        cyclistes ont été tués de nuit.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.page15 {

  .page_img {

    right: 1vw;
    left: initial;
    top: 15vh;
    height: auto;

    img {
      width: 35vw;
      height: auto;
    }
  }

  .page_content {
    margin-left: 6vw;
    margin-right: 35vw;
  }
}

</style>