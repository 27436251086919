<template>
  <div class="pages page14">
    <div class="page_content noTitle">
      <ul>
        <li>
          <strong>69%</strong> des cyclistes tués en 2016 ont plus de
          <strong>50</strong> ans.
        </li>
        <li>
          <strong>72%</strong> des cyclistes tués ou blessés étaient sur un
          trajet loisir.
        </li>
        <li>
          Les cyclistes tués ou blessés hospitalisés à la suite d'un accident
          sur leur trajet domicile travail représentent <strong>13%</strong>.
        </li>
      </ul>
      <img src="../assets/p1-4b_g1.jpg" alt="" /><img
        src="../assets/p1-4b_g2.jpg"
        alt=""
      />
      <p>
        Les agglomérations concentrent néanmoins <strong>84%</strong> des
        accidents impliquant un cycliste, <strong>48%</strong> de leur mortalité
        et <strong>69%</strong> des blessés hospitalisés.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page14 {
  .page_content {
    margin-left: 6vw;
  }

  img {
    width: 35vw;
    display: inline-block;
    margin: 0 5vw;
  }
}
</style>