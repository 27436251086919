import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);

app.config.globalProperties.appState = [
    {
        activePage: 1,
        activeChapter: 1,
        previousPage: "home",
        nextPage: "2"
    },
    {
        activePage: 2,
        activeChapter: 1,
        previousPage: "1",
        nextPage: "3"
    },
    {
        activePage: 3,
        activeChapter: 1,
        previousPage: "2",
        nextPage: "4"
    },    {
        activePage: 4,
        activeChapter: 1,
        previousPage: "3",
        nextPage: "5"
    },
    {
        activePage: 5,
        activeChapter: 1,
        previousPage: "4",
        nextPage: "6"
    },
    {
        activePage: 6,
        activeChapter: 1,
        previousPage: "5",
        nextPage: "7"
    },
    {
        activePage: 7,
        activeChapter: 1,
        previousPage: "6",
        nextPage: "8"
    },
    {
        activePage: 8,
        activeChapter: 1,
        previousPage: "7",
        nextPage: "9"
    },
    {
        activePage: 9,
        activeChapter: 1,
        previousPage: "8",
        nextPage: "10"
    },
    {
        activePage: 10,
        activeChapter: 1,
        previousPage: "9",
        nextPage: "11"
    },
    {
        activePage: 11,
        activeChapter: 1,
        previousPage: "10",
        nextPage: "12"
    },
    {
        activePage: 12,
        activeChapter: 1,
        previousPage: "11",
        nextPage: "13"
    },
    {
        activePage: 13,
        activeChapter: 1,
        previousPage: "12",
        nextPage: "14"
    },
    {
        activePage: 14,
        activeChapter: 1,
        previousPage: "13",
        nextPage: "15"
    },
    {
        activePage: 15,
        activeChapter: 1,
        previousPage: "14",
        nextPage: "16"
    },
    {
        activePage: 16,
        activeChapter: 1,
        previousPage: "15",
        nextPage: "17"
    },
    {
        activePage: 17,
        activeChapter: 2,
        previousPage: "16",
        nextPage: "18"
    },
    {
        activePage: 18,
        activeChapter: 2,
        previousPage: "17",
        nextPage: "19"
    },
    {
        activePage: 19,
        activeChapter: 2,
        previousPage: "18",
        nextPage: "20"
    },
    {
        activePage: 20,
        activeChapter: 2,
        previousPage: "19",
        nextPage: "21"
    },
    {
        activePage: 21,
        activeChapter: 2,
        previousPage: "20",
        nextPage: "22"
    },
    {
        activePage: 22,
        activeChapter: 2,
        previousPage: "21",
        nextPage: "23"
    },
    {
        activePage: 23,
        activeChapter: 2,
        previousPage: "22",
        nextPage: "24"
    },
    {
        activePage: 24,
        activeChapter: 2,
        previousPage: "23",
        nextPage: "25"
    },
    {
        activePage: 25,
        activeChapter: 3,
        previousPage: "24",
        nextPage: "26"
    },
    {
        activePage: 26,
        activeChapter: 3,
        previousPage: "25",
        nextPage: "27"
    },
    {
        activePage: 27,
        activeChapter: 3,
        previousPage: "26",
        nextPage: "28"
    },
    {
        activePage: 28,
        activeChapter: 3,
        previousPage: "27",
        nextPage: "29"
    },
    {
        activePage: 29,
        activeChapter: 3,
        previousPage: "28",
        nextPage: "30"
    },
    {
        activePage: 30,
        activeChapter: 3,
        previousPage: "29",
        nextPage: "31"
    },
    {
        activePage: 31,
        activeChapter: 3,
        previousPage: "30",
        nextPage: "32"
    },
    {
        activePage: 32,
        activeChapter: 3,
        previousPage: "31",
        nextPage: "33"
    },
    {
        activePage: 33,
        activeChapter: 3,
        previousPage: "32",
        nextPage: "34"
    },
    {
        activePage: 34,
        activeChapter: 4,
        previousPage: "33",
        nextPage: "35"
    },
    {
        activePage: 35,
        activeChapter: 4,
        previousPage: "34",
        nextPage: "36"
    },
    {
        activePage: 36,
        activeChapter: 4,
        previousPage: "35",
        nextPage: "37"
    },
    {
        activePage: 37,
        activeChapter: 4,
        previousPage: "36",
        nextPage: "38"
    },
    {
        activePage: 38,
        activeChapter: 5,
        previousPage: "37",
        nextPage: "39"
    },
    {
        activePage: 39,
        activeChapter: 5,
        previousPage: "38",
        nextPage: "end"
    },
]

app.config.globalProperties.chapters = [
    {
        firstPage: 1,
        links: [
            { title: 'Un mode de déplacement en croissance', page: 1 },
            { title: 'Le vélo à assistance électrique', page: 3 },
            { title: 'Un mode de déplacement encouragé', page: 6 },
            { title: 'Accidentologie en quelques chiffres', page: 13 },
        ]
    },
    {
        firstPage: 17,
        links: [
            { title: "Typologie des accidents", page: 17 },
            { title: 'Conseils pour rouler en toute sécurité', page: 23 },
        ]
    },
    {
        firstPage: 25,
        links: [
            { title: "La bonne voie", page: 25 },
            { title: "Typologie des aménagements", page: 26 },
            { title: "En voie de progression", page: 29 },
            { title: "Bandes ou pistes cyclables Avantages et inconvénients", page: 31 },
        ]
    },
    {
        firstPage: 34,
        links: [
            { title: "Équipements obligatoires", page: 36 },
            { title: 'Conseil pratiques', page: 37 },
        ]
    },
    {
        firstPage: 38,
        links: [
            { title: "Panneaux de signalisation pour cycles", page: 38 },
            { title: 'Infractions spécifiques vélos', page: 39 },
        ]
    },
];

//app.config.globalProperties.credits = false;

//createApp(App).use(router).mount('#app')
app.use(router).mount('#app')