<template>
  <div class="pages page26">
    <div class="page_content">
      <h1>Typologie des aménagements</h1>
      <ul>
        <li>
          <div>
          <img src="../assets/Panneaux-B22a-180.png" alt="" />
          <img src="../assets/Panneaux-C113-180.png" alt="" />
          <div>
            <p>
              Bande cyclable : voie exclusivement réservée aux cycles sur une
              chaussée à plusieurs voies.<br />
              Piste cyclable : chaussée exclusivement réservée aux cycles.
            </p>
          </div>
          </div>
        </li>
        <li><div>
          <img src="../assets/Panneaux-B27a-M4d1-180.png" alt="" />
          <div>
            <p>Couloirs mixte bus/vélos : voie réservée aux bus et ouverte aux
            cycles.</p>
          </div></div>
        </li>
        <li><div>
          <img src="../assets/Panneaux-C24a-180.png" alt="" />
          <div>
            <p>Double-sens cyclable : voie à double sens dont un sens est
            exclusivement réservé à la circulation des cycles.</p>
          </div></div>
        </li>
        <li><div>
          <img src="../assets/Panneaux-C115-180.png" alt="" />
          <div>
            <p>Voie verte : route exclusivement réservée à la circulation des
            véhicules non motorisés, des piétons, voire des cavaliers.</p>
          </div></div>
        </li>
        <li><div>
          <img src="../assets/Panneaux-B30-180.png" alt="" />
          <div>
            <p>Zone 30 : section affectée à la circulation de tous les usagers,
            dont la vitesse est limitée à 30 km/h.</p>
          </div></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page26 {
  .page_content {
    margin-left: 8vw;

    h1 {
      text-indent: -1.6vw;
    }

    img {
      width: 5vw;
      height: 5vw;
      display: inline-block;
    }

    ul {
      list-style-type: none;
    }

    li {
      text-indent: initial;

      &::before {
        content: none;
      }

      &:first-child {
        text-indent: -5vw;
      }
    }

    li > div {
      display: inline-flex;
      text-indent: 0;

      div {
        padding-left: 2vw;
      }
    }
  }
}
</style>