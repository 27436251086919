<template>
  <div class="pages page6">
    <div class="page_img"><img src="../assets/bande-cyclable-sur-trottoir.jpg" alt="" /></div>
    <div class="page_content">
      <h1>Un mode de déplacement encouragé</h1>
      <p>
        Les engagements de l'Etat et des collectivités en matière de réduction
        des gaz à effet de serre, de diminution de la pollution et de
        désengorgement des routes ont motivé la mise en place de plusieurs
        mesures et actions publiques.
      </p>
      <p>
        Le « plan vélo » présenté le 14 septembre par le gouvernement a pour
        ambition de tripler le nombre des déplacements à vélo, pour les faire
        passer de <strong>3</strong> à <strong>9%</strong> d'ici 2024. Il s'appuie notamment sur le développement
        des axes cyclables, la création d'un forfait « mobilité durable », des
        évolutions du code de la route et des actions pédagogiques et
        incitatives.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page6 {
  .page_content {
    //padding-top: 5vh;
  }
}
</style>