<template>
  <div class="pages page19">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/p2-1b_direction.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Changement de direction à une intersection</h1>
      <p>A une intersection, le cycliste se déporte pour tourner à gauche.</p>
      <p>
        Un véhicule automobile arrive par l'arrière, le cycliste coupe sa
        trajectoire.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557965358.hd.mp4?s=36798b90f98e362a20829843524b72d8b25b65ab&profile_id=175"
        thumbnail="thumbnail-risque2.jpg"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <ul>
          <li>Se déporter de la droite vers la gauche de la chaussée bien en amont de l'intersection.</li>
          <li>Effectuer un contrôle avant tout changement de direction (l'usage d'un rétroviseur sur le vélo est un avantage).</li>
          <li>Signaler son changement de direction en tendant le bras du côté de la direction où l'on souhaite tourner.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page19",

  data() {
    return {
      showConseil: false,
    };
  },

  components: {
    miniPlayer,
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page19 {

.page_content {
    margin-left: 40vw;
  }

  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>