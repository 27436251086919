<template>
  <div class="pages page39">
    <div class="page_content">
      <h1>Code de la route - Infractions spécifiques vélos</h1>
      <p>
        Nota: Les infractions concernant les cycles n'entraînent ni retrait de
        points ni suspension du permis de conduire
      </p>
      <div class="penaltys">
        <div class="penaltysTable">
          <table>
            <thead>
              <tr>
                <th>Nature du délit ou de l'infraction</th>
                <th>Classe des amendes ou délit</th>
                <th>Amende forfaitaire minorée</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Conduite d'un cycle non équipé de deux dispositifs de freinage
                  efficaces
                </td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>
                  Circulation de cycle, de nuit, sans utilisation des feux
                  d'éclairage et de signalisation
                </td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>
                  Circulation de cycle, par visibilité insuffisante, sans
                  utilisation des feux d'éclairaige et de signalisation
                </td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>
                  Conduite de cycle, de nuit ou par visibilité insuffisante, de
                  cycle non muni de feu de position avant non conforme
                </td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>
                  Conduite de nuit de cycle non muni de feu de position arrière
                  conforme
                </td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>
                  Conduite par visibilité insuffisante de cycle non muni de feu
                  de position arrière conforme
                </td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>
                  Conduite de cycle non muni de catadioptres de pédales
                  conformes
                </td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>
                  Conduite de cycle non muni de catadioptres avant conformes
                </td>
                <td>1</td>
                <td>11€</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="penaltysTable">
          <table>
            <thead>
              <tr>
                <th>Nature du délit ou de l'infraction</th>
                <th>Classe des amendes ou délit</th>
                <th>Amende forfaitaire minorée</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Publicité lumineuse sur un cycle</td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>Publicité par appareil réfléchissant sur un cycle</td>
                <td>1</td>
                <td>11€</td>
              </tr>
              <tr>
                <td>
                  Conduite d'un cycle à une vitesse excessive sur aire piétonne
                </td>
                <td>2</td>
                <td>22€</td>
              </tr>
              <tr>
                <td>
                  Conduite d'un cycle gênante pour les piétons sur aire piétonne
                </td>
                <td>2</td>
                <td>22€</td>
              </tr>
              <tr>
                <td>
                  Conduite à une vitesse excessive d'un cycle sur un trottoir
                  autorisé hors agglomération en présence d'habitations
                </td>
                <td>2</td>
                <td>22€</td>
              </tr>
              <tr>
                <td>
                  Conduite à une vitesse excessive d'un cycle sur un trottoir
                  autorisé hors agglomération en présence de piétons
                </td>
                <td>2</td>
                <td>22€</td>
              </tr>
              <tr>
                <td>
                  Conduite à une vitesse excessive d'un cycle sur une contre
                  allée autorisée hors agglomération en présence d'habitations
                </td>
                <td>2</td>
                <td>22€</td>
              </tr>
              <tr>
                <td>
                  Conduite à une vitesse excessive d'un cycle sur une contre
                  allée autorisée hors agglomération en présence de piétons
                </td>
                <td>2</td>
                <td>22€</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.page39 {
  .page_content {
    margin-left: 4vw;

    h1 {
      margin-bottom: 1vh;
    }

    p {
      margin-bottom: 1vh;
      margin-top: 1vh;
    }

    .penaltysTable {
      display: inline-block;
      width: 46vw;
    }

    .penaltysTable:last-child {
      margin-left: 1vw;
    }

    table {
      font-size: 1vw;
      border-spacing: 0;
      color: #000;
      table-layout: fixed;
    }

    th {
      background-color: #3f3853;
      color: #f1e966;
      border-right: #5d9ad3 solid 2px;
      padding: .5vh 1vw;

      &:first-child {
        width: 30vw;
      }
      &:not(:first-child) {
        width: 5vw;
      }

      &:last-of-type {
        border: 0;
      }
    }

    td {
      text-align: center;
      border-right: #000 solid 2px;
      padding: 0.6vw;
      font-size: 1.1vw;
      line-height: 1.1vw;
      height: 3.8vh;

      &:first-child {
        text-align: left;
      }

      &:last-of-type {
        border: 0;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #aaa;
        }
      }
    }
  }
}
</style>



 