<template>
  <div class="pages page28">

    <div class="page_content noTitle">
<h2>Quel contexte, quelle voie ?</h2>
<p>Le choix des aménagements est principalement réalisé en fonction de la vitesse des véhicules et de l'importance du trafic.
Mais aussi en fonction :</p>
<ul>
  <li>Du nombre d'intersections. Plus il y a d'intersections, plus les bandes cyclables sont favorisées au détriment des pistes.</li>
  <li>La complexité des carrefours.</li>
  <li>La présence de stationnement.</li>
  <li>Le passage de transports en commun.</li>
</ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page28 {

    .page_content {
    margin-left: 6vw;
  }
  
}
</style>