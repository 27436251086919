<template>
  <div class="pages page25">
    <div class="page_content">
      <h1>La bonne voie</h1>
      <h2>Des aménagements variés</h2>
      <p>
        Un aménagement cyclable est destiné à organiser la voirie pour
        accueillir des cycles non motorisés. Ces aménagements peuvent prendre
        plusieurs formes :
      </p>
      <ul>
        <li>Une chaussée dédiée aux cyclistes.</li>
        <li>Des panneaux indicateurs et une signalétique spécifique.</li>
        <li>Des facilités de circulation.</li>
      </ul>
      <p>
        Les formes de ces aménagements diffèrent essentiellement par le degré
        d'isolement de la circulation cyclable avec l'ensemble des voies
        destinées aux véhicules motorisés.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557966407.hd.mp4?s=0abc8566b5e122bc32d5fe6a7c7ff22c66bc3e99&profile_id=175"
        thumbnail="thumbnail-bonne_voie.jpg"
      ></miniPlayer>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page25",

  components: {
    miniPlayer,
  },

};
</script>


<style lang="scss" scoped>
.page25 {
  .page_content {
    margin-left: 5vw;
    margin-right: 69vw;
  }

  video {
    right: 0;
    top: 0;
    height: 69vh;
    width: auto;
  }

  
}
</style>