<template>
  <div class="pages page18">
    <div class="page_img" :class="showConseil ? 'conseilActive' : ''">
      <img src="../assets/p2-1a_portiere.jpg" alt="" />
    </div>
    <div class="page_content" :class="showConseil ? 'conseilActive' : ''">
      <h1>Ouverture de portière</h1>
      <p>
        En situation de circulation sur la chaussée en bordure d'espaces de
        stationnement.
      </p>
      <p>Avec ou sans bande cyclable.</p>
      <p>
        Le conducteur du véhicule automobile en stationnement ouvre sa portière
        sans contrôle de rétroviseur/ ou le cycliste passe dans son angle-mort.
      </p>
      <miniPlayer
        src="https://player.vimeo.com/external/557967680.hd.mp4?s=e0cacbfc8f9c7b1a3d386591dfb1ab547a2b8e7b&profile_id=175"
        thumbnail="thumbnail-risque1.jpg"
      ></miniPlayer>
      <button @click="toggleConseil(true)"></button>
    </div>
    <div class="pageConseil" v-show="showConseil" @click="toggleConseil(false)">
      <div class="conseilContent">
        <h1>Conseils :</h1>
        <ul>
          <li>Circuler à environ 1 mètre des véhicules en stationnement.</li>
          <li>
            Être vigilant sur la présence d'un conducteur dans le véhicule.
          </li>
          <li>
            Être visible grâce à une tenue claire et/ou un gilet
            rétro-réfléchissant, d’ailleurs obligatoire hors agglomération, la
            nuit et lorsque les conditions de visibilité sont mauvaises.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import miniPlayer from "@/components/miniPlayer.vue";

export default {
  name: "Page18",

  data() {
    return {
      showConseil: false,
    };
  },

  components: {
    miniPlayer,
  },

  methods: {
    toggleConseil: function (showHide) {
      this.showConseil = showHide;
    },
  },
};
</script>


<style lang="scss" scoped>
.page18 {

  .page_content {
    margin-left: 40vw;
  }

  video {
    bottom: 5vh;
    right: initial;
    margin-left: 4vw;
  }
}
</style>