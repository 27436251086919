<template>
  <div class="pages page4">
    <div class="page_img"><img src="../assets/vae.jpg" alt="" /></div>
    <div class="page_content noTitle">
      <h2>Aides de l'État</h2>
      <p>Jusqu'en janvier 2018, l'Etat offrait <strong>200</strong>€ pour l'achat d'un vélo électrique. Cette aide a été maintenue mais sous conditions beaucoup plus drastiques :
</p>
      <ul>
        <li>L'aide n'est attribuée qu'aux personnes non imposables sur le revenu.
</li>
        <li>Cette aide ne peut être attribuée que si une collectivité locale attribue également un même type d'aide.</li>
        <li>Le montant de l'aide de l'Etat ne peut excéder le montant de l'aide accordée par la collectivité locale.</li>
        <li>Le montant des deux aides cumulées ne peut être supérieur à <strong>20%</strong> du coût d'acquisition du véhicule ou de <strong>200</strong>€.</li>
      </ul>
      <p>De nombreuses villes ont également développé des aides à l'achat de VAE. 
Paris subventionne jusqu'à <strong>400</strong>€ l'achat d'un VAE.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page4",

  methods: {
    goto: function (page) {
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss" scoped>
.page4 {

  .page_button {
    img {
      display: inline-block;
      width: 10vw;
      margin: 4vw 4vw;
      cursor: pointer;
    }
  }
}
</style>